<div class="content sky-border-solid" [class.highlight]="highlight" [attr.data-tree]="tree.guid">
  <div class="spirit-tree-scroll" [class.pad-bottom]="hasCostAtRoot || padBottom">
    <!-- Action indication -->
    <div class="tree-action-background">
      @switch (forceNodeAction ?? nodeAction) {
        @case ('navigate') { <mat-icon>search</mat-icon> }
        @case ('favourite') { <mat-icon>star</mat-icon> }
      }
    </div>
    <!-- Controls -->
    @if (enableControls) {
      <!-- Top left controls -->
      <div class="tree-actions tree-actions-left">
        @if (showingNodeActions || nodeAction === 'unlock') {
          <div class="tree-action point" (click)="setNodeAction($event, 'unlock')">
            <div class="tree-action-icon find-item">
              <mat-icon>lock_open</mat-icon>
            </div>
            <span [class.active]="showingNodeActions && nodeAction === 'unlock'">Unlock</span>
          </div>
        }
        @if (showingNodeActions || nodeAction === 'navigate') {
          <div class="tree-action point" (click)="setNodeAction($event, 'navigate')">
            <div class="tree-action-icon find-item">
              <mat-icon>search</mat-icon>
            </div>
            <span [class.active]="showingNodeActions && nodeAction === 'navigate'">Navigate</span>
          </div>
        }
        @if (showingNodeActions || nodeAction === 'favourite') {
          <div class="tree-action point" (click)="setNodeAction($event, 'favourite')">
            <div class="tree-action-icon find-item">
              <mat-icon>star</mat-icon>
            </div>
            <span [class.active]="showingNodeActions && nodeAction === 'favourite'">Favourite</span>
          </div>
        }
      </div>

      <!-- Top right controls-->
      <div class="tree-actions tree-actions-right">
        <div class="tree-sub-action point" (click)="showingMenuActions=!showingMenuActions" [ngbTooltip]="'Toggle menu'" container="body" placement="top-end">
          <div class="tree-action-icon">
            <mat-icon>menu</mat-icon>
          </div>
        </div>
        <div class="tree-sub-action point" (click)="unlockAll()" [ngbTooltip]="toggleUnlock ? 'Unlock all': 'Lock all'" container="body" placement="top-end">
          <div class="tree-action-icon">
            <mat-icon>enhanced_encryption</mat-icon>
          </div>
        </div>
        @if (showingMenuActions) {
          <div class="tree-sub-action point" (click)="export('share')" [ngbTooltip]="'Share tree image'" container="body" placement="top-end">
            <div class="tree-action-icon">
              <mat-icon>share</mat-icon>
            </div>
          </div>
          <div class="tree-sub-action point" (click)="export('clipboard')" [ngbTooltip]="'Copy tree image'" container="body" placement="top-end">
            <div class="tree-action-icon">
              <mat-icon>file_copy</mat-icon>
            </div>
          </div>
          <a class="tree-sub-action point" (click)="editTree()" [ngbTooltip]="'Edit tree'" container="body" placement="top-end">
            <div class="tree-action-icon">
              <mat-icon>edit</mat-icon>
            </div>
          </a>
        }
      </div>
    }

    <!-- Tree nodes -->
    <div class="spirit-tree">
      <div class="column left">
        <div class="st-item" *ngFor="let node of left">
          <ng-container [ngTemplateOutlet]="tNode" [ngTemplateOutletContext]="{ $implicit: node, position: 'left' }"></ng-container>
        </div>
      </div>
      <div class="column center">
        <div class="st-item" *ngFor="let node of center">
          <ng-container [ngTemplateOutlet]="tNode" [ngTemplateOutletContext]="{ $implicit: node, position: 'center' }"></ng-container>
        </div>
      </div>
      <div class="column right">
        <div class="st-item" *ngFor="let node of right">
          <ng-container [ngTemplateOutlet]="tNode" [ngTemplateOutletContext]="{ $implicit: node, position: 'right' }"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    @if (visibleName) {
      <div class="name">
        <ng-content select="div[name]"></ng-content>
        <div class="name-default">
          {{ visibleName }}
          @if (tsDate || rsDate) {
            <span class="c-accent ts-date">(<app-date [date]="tsDate || rsDate"></app-date>)</span>
          }
        </div>
      </div>
    }
    @if (visibleName && hasCost) {
      <div class="hr"></div>
    }
    @if (hasCost) {
      <div class="costs">
        <app-cost [cost]="totalCost" [remaining]="remainingCost"></app-cost>
      </div>
    }
    @if (tree.draft) {
      <div class="hr"></div>
      <div class="draft c-accent">
        <small>This spirit tree is not confirmed yet!</small>
      </div>
    }
  </div>
</div>

<ng-template #tNode let-node let-position="position">
  @if (node) {
    <app-node [node]="node" [action]="forceNodeAction ?? nodeAction" [position]="position" (nodeClicked)="onNodeClicked($event, node)"
      [highlight]="highlightItem ? highlightItemMap[node.item?.guid] : undefined"
      [showTooltips]="showNodeTooltips"
      [opaque]="opaqueNodesAll || opaqueNodesMap[node.guid]"
      [glowType]="(forceNodeAction ?? nodeAction) !== 'unlock' ? 'attention' : 'default'">
    </app-node>
  }
  @if (nodeOverlayTemplate) { <ng-container [ngTemplateOutlet]="nodeOverlayTemplate" [ngTemplateOutletContext]="{ $implicit: node }"></ng-container> }
  <!-- Node arrows -->
  @if (node?.nw) { <div class="arrow arrow-left"></div> }
  @if (node?.n) { <div class="arrow arrow-up"></div> }
  @if (node?.ne) { <div class="arrow arrow-right"></div> }
</ng-template>
