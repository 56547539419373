<div [attr.data-position]="position" [class.highlight]="highlight">
  @if (node.item) {
    <a class="item-container"
      [routerLink]="link?.route" [queryParams]="link?.extras?.queryParams"
      [ngbTooltip]="showTooltips ? nodeHover : undefined" [placement]="tooltipPlacement" container="body">
      <app-item-icon [item]="node.item" [node]="node" [opaque]="hover || opaque" [glowType]="glowType" [subIcons]="['favourite', 'unlock', 'seasonPass']"
        (click)="nodeClick($event)" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"></app-item-icon>
    </a>
  }
  @if (node.c) {
    <div class="cost">
      {{ node.c }}<mat-icon class="icon-middle" svgIcon="candle" [inline]="false"></mat-icon>
    </div>
  }
  @if (node.sc) {
    <div class="cost">
      {{ node.sc }}<mat-icon class="icon-middle" svgIcon="season-candle" [inline]="false"></mat-icon>
    </div>
  }
  @if (node.h) {
    <div class="cost">
      {{ node.h }}<mat-icon class="icon-middle" svgIcon="heart" [inline]="false"></mat-icon>
    </div>
  }
  @if (node.sh) {
    <div class="cost">
      {{ node.sh }}<mat-icon class="seasonal icon-middle" svgIcon="season-heart" [inline]="false"></mat-icon>
    </div>
  }
  @if (node.ac) {
    <div class="cost ascend">
      {{ node.ac }}<mat-icon class="icon-middle" svgIcon="ascended-candle" [inline]="false"></mat-icon>
    </div>
  }
  @if (node.ec) {
    <div class="cost">
      {{ node.ec }}<mat-icon class="icon-middle" svgIcon="ticket" [inline]="false"></mat-icon>
    </div>
  }
</div>

<ng-template #nodeHover>
  @if (node.item) {
    <span class="ws-nw">{{node.item.name}}</span>
  }
</ng-template>
